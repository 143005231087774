<template>
  <base-layout class="order-view">
    <template #fixedTop>
      <!-- tab切换 -->
      <van-tabs v-model:active="insuStatus" animated class="tabs" @change="tabsChange">
        <van-tab title="全部" name="" />
        <van-tab title="已完成" name="1" />
        <van-tab title="待处理" name="0" />
      </van-tabs>
    </template>

    <!-- 汇总信息 -->
    <div class="count">
      累计&nbsp;<span>{{ totalCount }}</span
      >&nbsp;单，保费&nbsp;<span>{{ formatNum2Str(totalPrice) }}</span
      >&nbsp;元
    </div>

    <!-- 无数据 -->
    <div class="g-l-c-c empty-box" v-if="list.length === 0">
      <div>
        <van-empty
          description="- 暂无数据 -"
          image="https://bdh5.htprudent.com/G1/M00/1D/EC/CiICH2B2t1CAGFykAAA8CG29JIo719.png"
        />
        <van-button type="success" block @click="toMarketingPage">马上去推广</van-button>
      </div>
    </div>

    <!-- 列表 -->
    <div v-else v-for="(item, index) in list" :key="index" class="order-box">
      <order-item :data="item" />
    </div>
  </base-layout>
</template>

<script>
import qs from "qs";
import { selectOrderByReferee } from "@/api/index";
import { formatNum2Str, toMarketingPage } from "@/utils";
import OrderItem from "./OrderItem";

export default {
  components: { OrderItem },

  data() {
    return {
      insuStatus: "",
      totalCount: 0,
      totalPrice: 0,
      list: [],
      count: 0
    };
  },

  async created() {
    document.title = "我的订单";
    const { insuStatus = "" } = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (insuStatus) {
      this.insuStatus = insuStatus;
    }
    await this.fetchList();
  },

  methods: {
    formatNum2Str,
    toMarketingPage,
    async fetchList() {
      try {
        const _count = this.count;
        let { data } = await selectOrderByReferee({
          insuStatus: this.insuStatus
        });
        if (_count !== this.count) {
          return;
        }

        data = data || {};

        this.totalCount = data.totalCount || 0;
        this.totalPrice = data.totalPrice || 0;
        this.list = data.ordPolicyInfoDTOList || [];
        this.count += 1;
      } catch (error) {
        // TODO: 异常场景待处理
        console.error("error:", error);
      }
    },
    async tabsChange() {
      await this.fetchList();
    }
  }
};
</script>

<style lang="less" scoped>
.order-view {
  background-color: #f3f6f7;
}

.tabs {
  /deep/ .van-tab {
    font-size: 14px;
    color: #333333;
  }
  /deep/ .van-tab--active {
    font-size: 14px;
    color: #00b955;
    text-align: center;
  }
  /deep/ .van-tabs__line {
    background-color: #00b955;
  }
}

.count {
  padding: 0 14px;
  height: 48px;
  line-height: 48px;
  // background-color: #f3f6f7;
  font-size: 14px;
  color: #191919;

  > span {
    font-size: 17px;
    color: #ff7b01;
  }
}

.empty-box {
  // height: calc(100% - 48px);
  padding-top: 60px;
  & > div {
    width: 211px;
    text-align: center;
  }

  /deep/ .van-button {
    font-size: 18px;
    color: #ffffff;
    letter-spacing: 0;
    background: #00b955;
    border-radius: 4px;
  }
}

.order-box {
  & > * {
    margin-bottom: 10px;
  }
}
</style>
