<template>
  <div class="order">
    <div class="g-l-row-lf title">
      <div class="title-left">{{ data.prdName }}</div>
      <!--
      <div class="title-right" v-if="data.insuStatus === 1">已完成</div>
      <div class="title-right" v-if="data.insuStatus === 0" style="color: #ff7b01">待处理</div>
      -->

      <!-- policyStatus: 保单状态 -->
      <div class="title-right" v-if="data.policyStatus === 1" style="color: #ff7b01">待支付</div>
      <div class="title-right" v-if="data.policyStatus === 2" style="color: #ff7b01">出单中</div>
      <div class="title-right" v-if="data.policyStatus === 3" style="color: #808080">已关闭</div>
      <div class="title-right" v-if="data.policyStatus === 4" style="color: #00b955">已完成</div>
      <div class="title-right" v-if="data.policyStatus === 5" style="color: #00b955">已完成</div>
      <div class="title-right" v-if="data.policyStatus === 6" style="color: #808080">已取消</div>
      <div class="title-right" v-if="data.policyStatus === 7" style="color: #808080">已退保</div>
      <div class="title-right" v-if="data.policyStatus === 8" style="color: #808080">已取消</div>
      <div class="title-right" v-if="data.policyStatus === 9" style="color: #808080">已取消</div>
    </div>

    <div class="g-l-row-lf order-info">
      <div>投保人：</div>
      <div>{{ data.holderName }}</div>
    </div>
    <div class="g-l-row-lf order-info">
      <div>手机号：</div>
      <div>{{ data.holderPhone }}</div>
    </div>
    <div class="g-l-row-lf order-info">
      <div>投保时间：</div>
      <div>{{ data.createtime }}</div>
    </div>

    <div class="order-info2">
      <div>保单号：{{ data.insuCode }}</div>
      <div class="m-t-4">被保人：{{ data.insuredName }}</div>
    </div>

    <div class="expenses">
      <span class="m-r-20">保费：¥{{ formatNum2Str(data.stressPremium) }}</span
      ><span>推广费：¥{{ formatNum2Str(data.salerCommission) }}</span>
    </div>
  </div>
</template>

<script>
import { formatNum2Str } from "@/utils";

export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },

  methods: {
    formatNum2Str
  }
};
</script>

<style lang="less" scoped>
.m-r-20 {
  margin-right: 20px;
}

.m-t-4 {
  margin-top: 4px;
}

.order {
  padding: 0 14px;
  background-color: #fff;
}

.title {
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  color: #191919;
  letter-spacing: 0.33px;
  border-bottom: 0.5px dotted rgba(25, 25, 25, 0.16);

  &-right {
    font-size: 14px;
    color: #00b955;
    letter-spacing: 0.29px;
  }
}

.expenses {
  height: 40px;
  line-height: 40px;
  text-align: right;

  font-size: 14px;
  color: #191919;
  letter-spacing: 0.29px;
}

.order-info {
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
  letter-spacing: 0.29px;
}

.order-info2 {
  position: relative;
  margin-top: 8px;
  padding: 10px;
  border: 1px solid #f6f3eb;
  border-radius: 4px;
  background: #fffdf7;

  font-size: 14px;
  line-height: 20px;
  color: #7f6f5c;
  letter-spacing: 0.29px;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 54px;
    height: 58px;
    background-image: url(~@/assets/order_tag.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
